<template>
  <ClientOnly>
    <template #fallback>
      <arora-nuxt-link
        v-for="subgroup of menuStore.CurrentSubgroups"
        :key="subgroup.ID"
        :href="subgroup.Link"
        :label="clean(subgroup.Name)"
      />
    </template>
    <menu-subgroups-dol-guldur v-if="appConfig.VueSettingsPreRun.MenuSubgroupsLayout === 'DolGuldur'" />
    <menu-subgroups-dol-amroth
      v-else-if="appConfig.VueSettingsPreRun.MenuSubgroupsLayout === 'DolAmroth'"
    />
  </ClientOnly>
</template>

<script setup lang="ts">
const appConfig = useAppConfig()
const menuStore = useMenuStore()
const { clean } = useI18nSanitized()
</script>
